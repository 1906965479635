
import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import AlbumLayout from './album_layout';
import { useTranslation } from 'react-i18next';
import { useStyles } from './album_layout';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import { MoreButton } from './more_button';
import { Badge, Breadcrumbs } from "@material-ui/core";
import { answersByQuizId } from '../graphql/queries';
import { Auth, API } from 'aws-amplify';

const Persona = ( props: any) => {

  const persona = props.pageContext.persona;
  const pagePath = props.pageContext.pagePath;
  const referencesImage = props.pageContext.referencesImage;
  const [visitedQuizzes, setVisitedQuizzes] = React.useState({});
  const [user, setUser] = React.useState({});
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const image = persona.imageFile[0].childImageSharp.fluid;


  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Auth.currentAuthenticatedUser().then((_user) => {
        setUser(_user);
      });
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {

      const fetchLocal = async () => {
        const visited_quizzes = {};
        Object.values(persona.quizzes).forEach((quiz) => {
          if (localStorage.getItem(`answers-${quiz.slug}`)) {
            const local_object = {};
            const local_answers = JSON.parse(localStorage.getItem(`answers-${quiz.slug}`))
            local_answers.forEach((a) => local_object[a.question_id] = a);
            visited_quizzes[quiz.slug] = Object.keys(local_object).length;
          }
        });
        setVisitedQuizzes(visited_quizzes);
        console.log("visited_quizzes local", visited_quizzes);
      };

      const fetch =  async () => {
        const fetchFromServer = async (quiz) => {
          const evt = await API.graphql({
            query: answersByQuizId,
            variables: { quiz_id: quiz.slug },
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });
          if (evt.data.answersByQuizId.items.length > 0) {
            const local_object = {};
            const local_answers = evt.data.answersByQuizId.items;
            local_answers.forEach((a) => local_object[a.question_id] = a);
            visited_quizzes[quiz.slug] = Object.keys(local_object).length;
          }
        };
        const visited_quizzes = {};
        await Promise.all(Object.values(persona.quizzes).map(async (quiz) => {
          await fetchFromServer(quiz);
        }));
        setVisitedQuizzes(visited_quizzes);
      };

      if (user.username) {
        fetch();
      } else {
        fetchLocal();
      }
     
      return () => {
        isMounted = false;
      }
    };
    // eslint-disable-next-line
  }, [user]);

  return (
    <React.Fragment>
      }
      <AlbumLayout
        title={`${persona.name}`}
        description={`${persona.description}. ${persona.subdescription}`}
        fluid={image}
        language={persona.language}
        pagePath={pagePath}
        breadCrumbLinks={
          <Breadcrumbs className={classes.breadCrumb} aria-label="breadcrumb">
            <Link
              style={{ textDecoration: "none" }}
              color="inherit"
              to={`/personas/${persona.language}`}
            >
              <Typography color="secondary">{t("home")}</Typography>
            </Link>
            <Typography color="secondary">{persona.name}</Typography>
          </Breadcrumbs>
        }
      >
        {persona.quizzes &&
          Object.values(persona.quizzes).map((quiz) => {
            return (
              <Grid item key={quiz.id} xs={12} sm={6} md={4}>
                <Link to={`/quizzes/${quiz.language}/${quiz.slug}`}>
                  <Card className={classes.card}>
                    <Img
                      imgStyle={{ padding: "8px" }}
                      fluid={quiz.imageFile[0].childImageSharp.fluid}
                    />
                    <CardContent className={classes.cardContent}>
                      <Badge
                        overlap={"rectangle"}
                        badgeContent={`${
                          visitedQuizzes[quiz.slug]
                            ? visitedQuizzes[quiz.slug]
                            : 0
                        }/${quiz.question_count}`}
                        color="secondary"
                      >
                        <Typography gutterBottom variant="h5" component="h2" style={{paddingTop:'8px'}}>
                          {quiz.name}
                        </Typography>
                      </Badge>
                      <Typography>{quiz.description}</Typography>
                      <Typography>{quiz.subdescription}</Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            );
          })}
        {persona.slug !== "one-the-journey-begins" && (
          <Grid item key={`${persona.id}-references`} xs={12} sm={6} md={4}>
            <Link to={`/personas/${persona.language}/${persona.slug}/references`}>
              <Card className={classes.card}>
                {/* https://www.reddit.com/r/gatsbyjs/comments/hlh9rz/gatsby_image_and_material_ui/fx2cjna/ */}
                <Img
                  imgStyle={{ padding: "8px" }}
                  fluid={referencesImage.file.childImageSharp.fluid}
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {t("references-subdescription")}
                  </Typography>
                  <Typography>{t("references-comment")}</Typography>
                </CardContent>

              </Card>
            </Link>
          </Grid>
        )}
      </AlbumLayout>
    </React.Fragment>
  );
};

export default Persona;
