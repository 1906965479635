/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAnswer = /* GraphQL */ `
  query GetAnswer($id: ID!) {
    getAnswer(id: $id) {
      id
      question_id
      quiz_id
      answer_id
      updatedAt
      createdAt
      owner
    }
  }
`;
export const listAnswers = /* GraphQL */ `
  query ListAnswers(
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question_id
        quiz_id
        answer_id
        updatedAt
        createdAt
        owner
      }
      nextToken
    }
  }
`;
export const answersByQuizId = /* GraphQL */ `
  query AnswersByQuizId(
    $quiz_id: String
    $owner: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    answersByQuizId(
      quiz_id: $quiz_id
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        question_id
        quiz_id
        answer_id
        updatedAt
        createdAt
        owner
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      question_id
      quiz_id
      user_name
      user_avatar
      comment
      updatedAt
      createdAt
      owner
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question_id
        quiz_id
        user_name
        user_avatar
        comment
        updatedAt
        createdAt
        owner
      }
      nextToken
    }
  }
`;
export const commentsByCreatedAt = /* GraphQL */ `
  query CommentsByCreatedAt(
    $question_id: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByCreatedAt(
      question_id: $question_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        question_id
        quiz_id
        user_name
        user_avatar
        comment
        updatedAt
        createdAt
        owner
      }
      nextToken
    }
  }
`;
export const getAggregations = /* GraphQL */ `
  query GetAggregations($id: ID!) {
    getAggregations(id: $id) {
      id
      quiz_id
      comment_count
      like_count
      updatedAt
      createdAt
    }
  }
`;
export const listAggregationss = /* GraphQL */ `
  query ListAggregationss(
    $filter: ModelAggregationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAggregationss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        quiz_id
        comment_count
        like_count
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const byQuizId = /* GraphQL */ `
  query ByQuizId(
    $quiz_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelAggregationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byQuizId(
      quiz_id: $quiz_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        quiz_id
        comment_count
        like_count
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
